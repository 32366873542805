import { Component, inject } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {

  private readonly _auth = inject(AuthenticationService);
  private readonly _router = inject(Router);
  public readonly user$ = this._auth.currentUser$;

  //  S&M: for acceptance in the IOS app store
  public delete(): Promise<boolean> {
    console.log('🔍 Delete Account');
    return this._router.navigate(['/logout']);
  }

}
