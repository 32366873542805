import packageJson from 'package.json';
import { environment } from '../../environments/environment';
// Note: do not check in over this. When we run locally, we will be able to identify local testing using this.
export const buildInfo = {
  name: 'TripPortal',
  ionicAppId: environment.ionicAppId,
  ionicAppChannel: `dev.${packageJson.version}[0]`,
  publicVersion: packageJson.version,
  internalVersion: '2024.12.04-03.29.09.846',
  date: '2024-12-04T03:29:09.846Z',
};
