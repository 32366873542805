export const password = 'testMyRides42';
export const login = {
  "token": "testAccount",
  "userProfile": {
    "emailAddress": "store-test@cts-software.com",
    "isDelegateUser": false,
    "portalUserId": "testAccount",
    "comments": null,
    "transportationProvider": {
      "transportationProviderId": "5b938b42-9225-45e3-abe0-4d9616ae1abc",
      "name": "Cooper's Company",
      "phone": "9994563434",
      "logoImgUrl": null,
      "webPaymentToken": "TEST-TripMaster-Sandbox-c704cd224a78",
      "defaultLatitude": 40.748441,
      "defaultLongitude": -73.985664,
    },
    "transportationProviders": [
      {
        "transportationProviderId": "5b938b42-9225-45e3-abe0-4d9616ae1abc",
        "name": "Cooper's Company",
        "phone": "9994563434",
        "logoImgUrl": null,
        "webPaymentToken": "TEST-TripMaster-Sandbox-c704cd224a78",
        "defaultLatitude": 40.748441,
        "defaultLongitude": -73.985664,
      },
    ],
    "vendorTransportationProviders": [],
    "firstName": "Racoon",
    "middleInitial": null,
    "lastName": "Orange",
    "genderId": 0,
    "genderDescription": null,
    "dateOfBirth": "9/20/1982 12:00:00 AM",
    "phoneNumber": null,
    "address": {
      "addressId": "1",
      "name": "",
      "address1": "350 Fifth Avenue, NY 10118",
      "address2": "",
      "city": "New York",
      "state": "NY",
      "zip": "10118",
      "county": "",
      "latitude": 40.748441,
      "longitude": -73.985664,
      "phone": null,
      "isFavorite": false,
    },
    "rideRequirements": [],
    "prefersTextMessage": false,
    "isTestAccount": true,
  },
};
