<!-- S&M: for acceptance in the IOS app store -->
<div class="container default-background" cdkScrollable>

  <div>
    <h1>Profile</h1>
    <ng-container *ngIf="user$ | async as user">
      Logged in as <strong>{{ user.emailAddress }}</strong>
      <ng-container *ngIf="user.transportationProvider">
        <h2>Transportation Provider</h2>
        <dl>
          <dt>Name:</dt>
          <dd>{{ user.transportationProvider.name }}</dd>
          <dt>Phone:</dt>
          <dd>{{ user.transportationProvider.phone | phone }}</dd>
        </dl>
      </ng-container>

      <section class="delete">
        <div class="actions">
          <button type="button" class="warn" (click)="delete()">
            Delete Account
          </button>
        </div>
      </section>
    </ng-container>
  </div>

  <app-footer />

</div>
